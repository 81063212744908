<template>
  <div class="project">
    <div class="headerBox">
      <i class="el-icon-alidanlieliebiao"></i>
      <i class="el-icon-aliiconfontzhizuobiaozhun023101" @click="goHome"></i>
    </div>
    <!-- <div class="searchBox">
      <el-input placeholder="请输入内容" v-model="searchInput" class="input-with-select">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div> -->
    <ul class="listBox">
      <li v-for="(item,index) in projectList" :key="index" @click="clickProject(item)">
        <div class="mainBox">
          <div class="progress">完成进度：{{item.Completion}}</div>
        </div>
        <p>{{item.Name}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchInput: "",
      projectList: [],
    }
  },
  methods: {
    initData() {
      this.$axios.post('api/LearningProjects/GetList', "").then(res => {
        let data = JSON.parse(res.data);
        this.projectList = data.data;
      });
    },
    goHome() {
      location.reload();
    },
    clickProject(item) {
      this.cookies.kkSet("projectId", item.ID);
      this.cookies.kkSet("projectName", item.Name);
      this.$router.push({ name: "pattern" });
    },
  },
  mounted() {
    this.initData();
  },
}
</script>

<style scoped>
.headerBox {
  width: 100%;
  height: 88px;
  background: #4fc1b0;
  line-height: 88px;
}
.headerBox i {
  font-size: 32px;
  color: #fff;
  font-weight: bold;
  margin-left: 20px;
}
.searchBox {
  width: 360px;
  height: 40px;
  margin: 50px auto;
}
.listBox {
  width: 84%;
  margin-left: 8%;
  display: flex;
  flex-wrap: wrap;
}
.listBox li {
  max-width: calc(20%-40px);
  margin: 20px;
}
.listBox li .mainBox {
  width: 120px;
  height: 90px;
  background: #acc5db;
  position: relative;
}
.listBox li p {
  text-align: center;
  margin-top: 10px;
}
.listBox li .mainBox .progress {
  width: 100%;
  height: 20px;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  line-height: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  color: #797979;
}
</style>